// File: src/components/MetricsDashboard.js

import React, { useEffect, useState } from 'react';
import DashboardCard from './DashboardCard';
import { FiBriefcase, FiUsers, FiMail, FiBell } from 'react-icons/fi';
import { DataStore } from 'aws-amplify/datastore';
import { Restaurants, Job, Application, Notifications } from '../models'; // Updated import
import { useNavigate } from 'react-router-dom';

function MetricsDashboard({ role, currentUser }) {
  const navigate = useNavigate();
  const [totalRestaurants, setTotalRestaurants] = useState(0);
  const [activeJobs, setActiveJobs] = useState(0);
  const [pendingApplications, setPendingApplications] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        // Total Restaurants (only for SYSTEMADMIN)
        if (role === 'SYSTEMADMIN') {
          const restaurants = await DataStore.query(Restaurants);
          setTotalRestaurants(restaurants.length);
        }

        // Active Jobs (all for SYSTEMADMIN, specific for RESTAURANTADMIN)
        const jobs = role === 'SYSTEMADMIN' 
          ? await DataStore.query(Job, (j) => j.jobStatus.eq("OPEN"))
          : await DataStore.query(Job, (j) => j.jobStatus.eq("OPEN").and(j.restaurantsID.eq("YOUR_RESTAURANT_ID")));
        setActiveJobs(jobs.length);

        // Pending Applications (all for SYSTEMADMIN, specific for RESTAURANTADMIN)
        const applications = role === 'SYSTEMADMIN' 
          ? await DataStore.query(Application, (a) => a.status.eq("SUBMITTED"))
          : await DataStore.query(Application, (a) => a.status.eq("SUBMITTED").and(a.restaurantsID.eq("YOUR_RESTAURANT_ID")));
        setPendingApplications(applications.length);

        // Notifications (all for SYSTEMADMIN, user-specific for others)
        const notifications = role === 'SYSTEMADMIN'
          ? await DataStore.query(Notifications)
          : await DataStore.query(Notifications, (n) => n.userId.eq(currentUser.id));
        setNotificationCount(notifications.length);

      } catch (error) {
        console.error("Error fetching metrics:", error);
      }
    };

    fetchMetrics();
  }, [role, currentUser]);

  // Define metrics based on user role
  const metrics = role === 'SYSTEMADMIN' 
    ? [
        { label: 'Total Restaurants', value: totalRestaurants, icon: <FiBriefcase />, onClick: () => navigate('/restaurants') },
        { label: 'Active Jobs', value: activeJobs, icon: <FiUsers />, onClick: () => navigate('/jobs') },
        { label: 'Pending Applications', value: pendingApplications, icon: <FiMail />, onClick: () => navigate('/applications') },
        { label: 'Notifications', value: notificationCount, icon: <FiBell />, onClick: () => navigate('/notifications') }
      ]
    : [
        { label: 'Active Jobs', value: activeJobs, icon: <FiBriefcase />, onClick: () => navigate('/jobs') },
        { label: 'Pending Applications', value: pendingApplications, icon: <FiMail />, onClick: () => navigate('/applications') },
        { label: 'Notifications', value: notificationCount, icon: <FiBell />, onClick: () => navigate('/notifications') }
      ];

  return (
    <div className="grid grid-cols-2 gap-4 w-full max-w-4xl mx-auto mb-8">
      {metrics.map((metric, index) => (
        <DashboardCard
          key={index}
          label={metric.label}
          value={metric.value}
          icon={metric.icon}
          onClick={metric.onClick} // Pass onClick to DashboardCard
        />
      ))}
    </div>
  );
}

export default MetricsDashboard;
