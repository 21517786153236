import React, { useState, useEffect } from "react";
import { FiSave, FiX } from "react-icons/fi";
import { DataStore } from "aws-amplify/datastore";
import { Job, JobTemplate } from "../models";
import { useParams, useNavigate } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContext";

const POSITION_ENUM = [
  { label: "Server", value: "SERVER" },
  { label: "FOH Team Member", value: "FOH_TEAM_MEMBER" },
  { label: "BOH Team Member", value: "BOH_TEAM_MEMBER" },
  { label: "Cook", value: "COOK" },
  { label: "Chef", value: "CHEF" },
  { label: "Bartender", value: "BARTENDER" },
  { label: "Busser", value: "BUSSER" },
  { label: "Dishwasher", value: "DISHWASHER" },
];

const SHIFT_TYPE_ENUM = [
  { label: "Day/Lunch Shift", value: "DAY_LUNCH_SHIFT" },
  { label: "Dinner/Evening Shift", value: "DINNER_EVENING_SHIFT" },
  { label: "All-Day Event", value: "ALL_DAY_EVENT" },
];

function CreateJob() {
  console.log("CreateJob");
  console.log("Restaurant ID", useParams());
  const { id: restaurantId } = useParams();
  console.log("Restaurant ID", restaurantId);
  const navigate = useNavigate();
  const [newJob, setNewJob] = useState({
    position: "SERVER",
    shortDescription: "",
    description: "",
    minWage: "",
    maxWage: "",
    startTime: "",
    endTime: "",
    startDate: "", // Add this line
    shiftType: "DAY_LUNCH_SHIFT",
    jobStatus: "OPEN",
    responsibilities: "",
    jobRequirements: "",
    specialRequirements: false,
    imageFile: null,
    saveAsTemplate: false,
    isGlobal: false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // If the input is a checkbox, use 'checked' for boolean values
    const newValue = type === "checkbox" ? checked : value;

    setNewJob((prevState) => ({ ...prevState, [name]: newValue }));
  };

  const { role } = useAuthContext();

  const isSuperUser = role === "SYSTEMADMIN";

  console.log("isSuperUser", isSuperUser);

  const { authUser } = useAuthContext();
  console.log(authUser);

  const [templates, setTemplates] = useState([]); // To store the list of templates
  const [selectedTemplate, setSelectedTemplate] = useState(""); // To track the selected template

  const handleSave = async () => {
    try {
      // Convert startDate to AWSDateTime format
      const formattedStartDate = newJob.startDate
        ? `${newJob.startDate}T00:00:00Z`
        : null;

      

      await DataStore.save(
        new Job({
          name: newJob.position,
          position: newJob.position,
          shortDescription: newJob.shortDescription,
          description: newJob.description,
          minWage: parseFloat(newJob.minWage),
          maxWage: parseFloat(newJob.maxWage),
          startTime: newJob.startTime,
          endTime: newJob.endTime,
          startDate: formattedStartDate, // Add this line
          shiftType: newJob.shiftType,
          jobStatus: newJob.jobStatus,
          responsibilities: newJob.responsibilities,
          jobRequirements: newJob.jobRequirements,
          specialRequirements: newJob.specialRequirements,
          restaurantsID: restaurantId,
        })
      );

      // Save as template if the option is selected
      if (newJob.saveAsTemplate) {
        await DataStore.save(
          new JobTemplate({
            name: newJob.position,
            description: newJob.description,
            jobRequirements: newJob.jobRequirements,
            responsibilities: newJob.responsibilities,
            minWage: parseFloat(newJob.minWage),
            maxWage: parseFloat(newJob.maxWage),
            isGlobal: isSuperUser ? newJob.isGlobal : false,
            restaurantsID: restaurantId, // Ensure this is always set
            usersID: authUser?.username, // Replace with actual user ID field
          })
        );
      }

      navigate(`/restaurants/${restaurantId}`);
    } catch (error) {
      console.error("Error creating new job:", error);
    }
  };

  const fetchTemplates = async () => {
    try {
      const globalTemplates = await DataStore.query(JobTemplate, (t) =>
        t.isGlobal.eq(true)
      );
      const restaurantTemplates = await DataStore.query(JobTemplate, (t) =>
        t.restaurantsID.eq(restaurantId)
      );
      setTemplates([...globalTemplates, ...restaurantTemplates]);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleTemplateSelect = (e) => {
    const templateId = e.target.value;
    setSelectedTemplate(templateId);
    const template = templates.find((t) => t.id === templateId);
    if (template) {
      setNewJob({
        position: template.name,
        shortDescription: template.shortDescription || "",
        description: template.description || "",
        minWage: template.minWage || "",
        maxWage: template.maxWage || "",
        responsibilities: template.responsibilities || "",
        jobRequirements: template.jobRequirements || "",
        startTime: "",
        endTime: "",
        date: "",
        shiftType: "DAY_LUNCH_SHIFT",
        jobStatus: "OPEN",
        specialRequirements: false,
        imageFile: null,
      });
    }
  };

  const handleCancel = () => {
    navigate(`/restaurants/${restaurantId}`);
  };

  return (
    <div className="w-full max-w-4xl mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      {/* Breadcrumb Navigation */}
      <div className="text-gray-500 text-sm mb-4">
        <button
          onClick={() => navigate(`/restaurants/${restaurantId}`)}
          className="hover:underline"
        >
          Back to Restaurant
        </button>{" "}
        / <span className="font-semibold">Create New Job</span>
      </div>

      <h2 className="text-2xl font-bold text-gray-800 mb-6">Create New Job</h2>

      <div className="space-y-4">
        <label className="block text-sm font-medium text-gray-700">
          Import from Template
        </label>
        <select
          value={selectedTemplate}
          onChange={handleTemplateSelect}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary mb-4"
        >
          <option value="">Select a template</option>
          {templates.map((template) => (
            <option key={template.id} value={template.id}>
              {template.name} ({template.isGlobal ? "Global" : "Restaurant"})
            </option>
          ))}
        </select>

        {/* Position Dropdown */}
        <label className="block text-sm font-medium text-gray-700">
          Position Title
        </label>
        <select
          name="position"
          value={newJob.position}
          onChange={handleInputChange}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
        >
          {POSITION_ENUM.map((position, index) => (
            <option key={index} value={position.value}>
              {position.label}
            </option>
          ))}
        </select>

        <label className="block text-sm font-medium text-gray-700">
          Responsibilities
        </label>
        <textarea
          name="responsibilities"
          value={newJob.responsibilities}
          onChange={handleInputChange}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
          placeholder="Enter job responsibilities"
        />

        <label className="block text-sm font-medium text-gray-700">
          Job Requirements
        </label>
        <textarea
          name="jobRequirements"
          value={newJob.jobRequirements}
          onChange={handleInputChange}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
          placeholder="Enter job requirements"
        />

        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            name="specialRequirements"
            checked={newJob.specialRequirements}
            onChange={handleInputChange}
            className="h-5 w-5 text-primary border rounded focus:ring-2 focus:ring-primary"
          />
          <label className="block text-sm font-medium text-gray-700">
            Special Requirements
          </label>
        </div>

        {/* Short Description */}
        <label className="block text-sm font-medium text-gray-700">
          Short Description
        </label>
        <textarea
          name="shortDescription"
          value={newJob.shortDescription}
          onChange={handleInputChange}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
          placeholder="Enter short description"
        />

        {/* Detailed Description */}
        <label className="block text-sm font-medium text-gray-700">
          Detailed Description
        </label>
        <textarea
          name="description"
          value={newJob.description}
          onChange={handleInputChange}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
          placeholder="Enter detailed job description"
        />

        {/* Shift Details */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Start Time
            </label>
            <input
              type="time"
              name="startTime"
              value={newJob.startTime}
              onChange={handleInputChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              End Time
            </label>
            <input
              type="time"
              name="endTime"
              value={newJob.endTime}
              onChange={handleInputChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="saveAsTemplate"
              checked={newJob.saveAsTemplate}
              onChange={handleInputChange}
            />
            <label className="block text-sm font-medium text-gray-700">
              Save as Template
            </label>
          </div>

          {isSuperUser && newJob.saveAsTemplate && (
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="isGlobal"
                checked={newJob.isGlobal}
                onChange={handleInputChange}
                className="h-5 w-5"
              />
              <label className="text-sm font-medium text-gray-700">
                Save Globally for All Restaurants
              </label>
            </div>
          )}
        </div>

        <label className="block text-sm font-medium text-gray-700">
          Start Date
        </label>
        <input
          type="date"
          name="startDate" // Ensure this matches the state key
          value={newJob.startDate}
          onChange={handleInputChange}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
        />

        {/* Shift Type Dropdown */}
        <label className="block text-sm font-medium text-gray-700">
          Shift Type
        </label>
        <select
          name="shiftType"
          value={newJob.shiftType}
          onChange={handleInputChange}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
        >
          {SHIFT_TYPE_ENUM.map((shift, index) => (
            <option key={index} value={shift.value}>
              {shift.label}
            </option>
          ))}
        </select>

        <label className="block text-sm font-medium text-gray-700">
          Upload Image
        </label>
        <input
          type="file"
          accept="image/*"
          onChange={(e) =>
            setNewJob({ ...newJob, imageFile: e.target.files[0] })
          }
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
        />
      </div>

      {/* Save and Cancel Buttons */}
      <div className="mt-6 flex space-x-4">
        <button
          onClick={handleSave}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center"
        >
          <FiSave className="mr-2" /> Save Job
        </button>
        <button
          onClick={handleCancel}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 flex items-center"
        >
          <FiX className="mr-2" /> Cancel
        </button>
      </div>
    </div>
  );
}

export default CreateJob;
