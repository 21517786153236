// File: src/components/CreateJobTemplate.js
import React, { useState } from 'react';
import { DataStore } from 'aws-amplify/datastore';
import { JobTemplate } from '../models';

function CreateJobTemplate() {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    jobRequirements: '',
    responsibilities: '',
    minWage: '',
    maxWage: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveTemplate = async () => {
    try {
      await DataStore.save(
        new JobTemplate({
          ...formData,
          usersID: 'SYSTEMADMIN_ID', // Replace this with actual SYSTEMADMIN ID
        })
      );
      alert('Job Template Created Successfully!');
      setFormData({ name: '', description: '', jobRequirements: '', responsibilities: '', minWage: '', maxWage: '' });
    } catch (error) {
      console.error('Error saving template:', error);
    }
  };

  return (
    <div className="p-4">
      <h2>Create Job Template</h2>
      <form className="space-y-4">
        <input name="name" placeholder="Template Name" value={formData.name} onChange={handleChange} />
        <textarea name="description" placeholder="Description" value={formData.description} onChange={handleChange} />
        <textarea name="jobRequirements" placeholder="Requirements" value={formData.jobRequirements} onChange={handleChange} />
        <textarea name="responsibilities" placeholder="Responsibilities" value={formData.responsibilities} onChange={handleChange} />
        <input type="number" name="minWage" placeholder="Min Wage" value={formData.minWage} onChange={handleChange} />
        <input type="number" name="maxWage" placeholder="Max Wage" value={formData.maxWage} onChange={handleChange} />
        <button type="button" onClick={handleSaveTemplate} className="btn-primary">Save Template</button>
      </form>
    </div>
  );
}

export default CreateJobTemplate;
