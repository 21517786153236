import React, { useEffect, useState } from 'react';
import { DataStore } from 'aws-amplify/datastore';
import { Notifications as NotificationModel, Application, Users } from '../models';
import { useAuthContext } from '../contexts/AuthContext';

const NOTIFICATION_TYPES = [
  { label: 'Job Accepted', value: 'JOBACCEPTED' },
  { label: 'Job Declined', value: 'JOBDECLINED' },
  { label: 'Job Filled', value: 'JOBFILLED' },
  { label: 'Restaurant Note', value: 'RESTAURANTNOTE' },
  { label: 'System Message', value: 'SYSTEMMSG' },
];

function Notifications({ currentUser }) {
  const [notifications, setNotifications] = useState([]);
  const [createdNotifications, setCreatedNotifications] = useState([]);
  const [message, setMessage] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedNotificationType, setSelectedNotificationType] = useState('SYSTEMMSG');
  const [users, setUsers] = useState([]);
  const [sending, setSending] = useState(false);
  const [sortOption, setSortOption] = useState('date');
  const [filterUserId, setFilterUserId] = useState('');

  useEffect(() => {
    fetchNotifications();
    fetchUsers();
  }, [currentUser]);

  const fetchNotifications = async () => {
    try {
      let userNotifications;

      if (currentUser.role === 'SYSTEMADMIN') {
        userNotifications = await DataStore.query(NotificationModel);
      } else {
        userNotifications = await DataStore.query(NotificationModel, (n) =>
          n.restaurantsID.eq(currentUser.restaurantsID)
        );
      }

      setNotifications(userNotifications);

      const createdByUser = await DataStore.query(NotificationModel, (n) =>
        n.memo.eq(`Created by ${currentUser.id}`)
      );
      setCreatedNotifications(createdByUser);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      let userList;

      if (currentUser.role === 'SYSTEMADMIN') {
        userList = await DataStore.query(Users);
      } else {
        const applications = await DataStore.query(Application, (a) =>
          a.restaurantsID.eq(currentUser.restaurantsID)
        );
        const userIds = [...new Set(applications.map((app) => app.usersID))];
        userList = await Promise.all(
          userIds.map(async (id) => await DataStore.query(Users, id))
        );
      }

      setUsers(userList);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSendNotification = async () => {
    if (!message || (!selectedUserId && selectedUserId !== 'ALL')) {
      alert('Please enter a message and select a user.');
      return;
    }

    setSending(true);

    try {
      const now = new Date().toISOString();

      if (selectedUserId === 'ALL') {
        const allUsers = await DataStore.query(Users);
        await Promise.all(
          allUsers.map(async (user) => {
            await DataStore.save(
              new NotificationModel({
                notificationMessage: message,
                notificationType: selectedNotificationType,
                usersID: user.id,
                date: now,
                acknowledged: false,
                memo: `Created by ${currentUser.id}`,
              })
            );
          })
        );
      } else {
        await DataStore.save(
          new NotificationModel({
            notificationMessage: message,
            notificationType: selectedNotificationType,
            usersID: selectedUserId,
            date: now,
            acknowledged: false,
            memo: `Created by ${currentUser.id}`,
          })
        );
      }

      alert('Notification sent successfully.');
      setMessage('');
      setSelectedUserId('');
      setSelectedNotificationType('SYSTEMMSG');
      fetchNotifications();
    } catch (error) {
      console.error('Error sending notification:', error);
    } finally {
      setSending(false);
    }
  };

  const handleMarkAsRead = async (notificationId) => {
    try {
      const original = await DataStore.query(NotificationModel, notificationId);
      if (original) {
        await DataStore.save(
          NotificationModel.copyOf(original, (updated) => {
            updated.acknowledged = true;
          })
        );
        fetchNotifications();
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const filteredNotifications = notifications
    .filter((notification) => {
      if (currentUser.role === 'SYSTEMADMIN' && filterUserId) {
        return notification.usersID === filterUserId;
      }
      return true;
    })
    .sort((a, b) => {
      if (sortOption === 'date') return new Date(b.date) - new Date(a.date);
      if (sortOption === 'status') return a.acknowledged - b.acknowledged;
      if (sortOption === 'type') return a.notificationType.localeCompare(b.notificationType);
      return 0;
    });

  return (
    <div className="w-full max-w-6xl mx-auto my-8">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">
        {currentUser.role === 'SYSTEMADMIN' ? 'All Notifications' : 'Restaurant Notifications'}
      </h1>

      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-lg font-semibold mb-4">Create Notification</h2>
        <textarea
          className="w-full p-3 border rounded mb-4"
          placeholder="Enter notification message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <select
          className="w-full p-3 border rounded mb-4"
          value={selectedNotificationType}
          onChange={(e) => setSelectedNotificationType(e.target.value)}
        >
          {NOTIFICATION_TYPES.map((type) => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>

        <select
          className="w-full p-3 border rounded mb-4"
          value={selectedUserId}
          onChange={(e) => setSelectedUserId(e.target.value)}
        >
          <option value="">Select User</option>
          {currentUser.role === 'SYSTEMADMIN' && <option value="ALL">Send to All Users</option>}
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name || user.email}
            </option>
          ))}
        </select>

        <button
          className={`bg-primary text-white px-4 py-2 rounded ${sending ? 'opacity-50' : 'hover:bg-primary-dark'}`}
          onClick={handleSendNotification}
          disabled={sending}
        >
          {sending ? 'Sending...' : 'Send Notification'}
        </button>
      </div>

      <div className="mb-4 flex space-x-4">
        <select
          className="p-3 border rounded"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
        >
          <option value="date">Sort by Date</option>
          <option value="status">Sort by Status</option>
          <option value="type">Sort by Type</option>
        </select>
      </div>

      <div className="space-y-4">
        {filteredNotifications.length > 0 ? (
          filteredNotifications.map((notification) => (
            <div key={notification.id} className="p-4 bg-white rounded-lg shadow-md flex justify-between items-center">
              <div>
                <p className="text-sm text-gray-800">{notification.notificationMessage}</p>
                <p className="text-sm text-gray-600">
                  Type: <span className="font-semibold">{notification.notificationType}</span>
                </p>
                <p className={`text-xs mt-1 ${notification.acknowledged ? 'text-green-600' : 'text-red-600'}`}>
                  Status: {notification.acknowledged ? 'Read' : 'Unread'}
                </p>
                <p className="text-xs text-gray-500 mt-1">
                  {new Date(notification.date).toLocaleString()}
                </p>
              </div>
              {!notification.acknowledged && (
                <button
                  className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                  onClick={() => handleMarkAsRead(notification.id)}
                >
                  Mark as Read
                </button>
              )}
            </div>
          ))
        ) : (
          <p className="text-gray-600">No notifications found.</p>
        )}
      </div>
    </div>
  );
}

export default Notifications;
