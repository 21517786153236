// File: src/components/FeaturedInsights.js
import React from 'react';

function FeaturedInsights() {
  const insights = [
    { label: 'Top-Rated Restaurant', value: 'Bistro Bar', details: 'Rating: 4.8 / 5' },
    { label: 'Most Job Openings', value: 'Downtown Area', details: 'Jobs: 12' },
    { label: 'Highest Applicant Rate', value: 'Central Cafe', details: 'Applications: 7 per job' },
  ];

  return (
    <div className="bg-cardBg p-6 rounded-lg shadow-md w-full max-w-4xl mx-auto">
      <h3 className="text-xl font-semibold text-white mb-4">Featured Insights</h3>
      <ul className="space-y-3">
        {insights.map((insight, index) => (
          <li key={index} className="flex flex-col">
            <p className="text-lg font-semibold text-primary">{insight.label}</p>
            <p className="text-white">{insight.value}</p>
            <p className="text-text-gray text-sm">{insight.details}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FeaturedInsights;
