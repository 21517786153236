// File: src/components/EditJob.js

import React, { useState, useEffect } from 'react';
import { FiSave, FiX, FiUpload } from 'react-icons/fi';
import { DataStore } from 'aws-amplify/datastore';
import { uploadData } from 'aws-amplify/storage';
import { Job, JobTemplate } from '../models';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';

const POSITION_ENUM = [
  { label: "Server", value: "SERVER" },
  { label: "FOH Team Member", value: "FOH_TEAM_MEMBER" },
  { label: "BOH Team Member", value: "BOH_TEAM_MEMBER" },
  { label: "Cook", value: "COOK" },
  { label: "Chef", value: "CHEF" },
  { label: "Bartender", value: "BARTENDER" },
  { label: "Busser", value: "BUSSER" },
  { label: "Dishwasher", value: "DISHWASHER" },
];

const SHIFT_TYPE_ENUM = [
  { label: "Day/Lunch Shift", value: "DAY_LUNCH_SHIFT" },
  { label: "Dinner/Evening Shift", value: "DINNER_EVENING_SHIFT" },
  { label: "All-Day Event", value: "ALL_DAY_EVENT" },
];

function EditJob() {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [editedJob, setEditedJob] = useState({
    saveAsTemplate: false,
    isGlobal: false,
    startDate: "", // Add this line
    // Include other fields here
  });

  const [uploadingImage, setUploadingImage] = useState(false);

  const { authUser, role } = useAuthContext();
  const isSuperUser = role === 'SYSTEMADMIN';

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const jobData = await DataStore.query(Job, jobId);
        setJob(jobData);
        setEditedJob(jobData);
      } catch (error) {
        console.error("Error fetching job data:", error);
      }
    };

    fetchJob();
  }, [jobId]);

  const handleSave = async () => {
    try {

      // Convert startDate to AWSDateTime format
      const formattedStartDate = editedJob.startDate
        ? `${editedJob.startDate}T00:00:00Z`
        : null;

      // Ensure restaurantsID is set in editedJob
      const updatedEditedJob = {
        ...editedJob,
        startDate: formattedStartDate, // Set formatted startDate
        restaurantsID: editedJob.restaurantsID || job.restaurantsID,
      };

      console.log("Saving job info:", updatedEditedJob);
      console.log("Original job:", job);

      // Save the updated job
      await DataStore.save(
        Job.copyOf(job, (updated) => {
          updated.minWage = parseFloat(updatedEditedJob.minWage);
          updated.maxWage = parseFloat(updatedEditedJob.maxWage);
          updated.startDate = updatedEditedJob.startDate; // Replace with the correct field name
          Object.assign(updated, updatedEditedJob);
        })
      );

      console.log("Job saved successfully.");

      // If the save-as-template checkbox is selected, save as a JobTemplate
      if (updatedEditedJob.saveAsTemplate) {
        const templateRestaurantsID = isSuperUser && updatedEditedJob.isGlobal
          ? 'GLOBAL_TEMPLATE'  // Default value for global templates
          : updatedEditedJob.restaurantsID;

        console.log("Creating JobTemplate with values:");
        console.log("Name:", updatedEditedJob.position);
        console.log("Restaurants ID:", templateRestaurantsID);
        console.log("Users ID:", authUser?.userId);

        await DataStore.save(
          new JobTemplate({
            name: updatedEditedJob.position,
            description: updatedEditedJob.description,
            jobRequirements: updatedEditedJob.jobRequirements,
            responsibilities: updatedEditedJob.responsibilities,
            minWage: parseFloat(updatedEditedJob.minWage),
            maxWage: parseFloat(updatedEditedJob.maxWage),
            isGlobal: isSuperUser ? updatedEditedJob.isGlobal : false,
            restaurantsID: templateRestaurantsID,
            usersID: authUser?.userId,
          })
        );

        console.log("JobTemplate saved successfully.");
      }

      navigate(-1); // Go back to the previous page
    } catch (error) {
      console.error("Error saving job info:", error);
    }
  };


  const handleCancel = () => {
    setEditedJob(job); // Revert to original data
    navigate(-1); // Go back to the previous page
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Convert to number for wage fields
    let newValue;
    if (name === "minWage" || name === "maxWage") {
      newValue = value === "" ? "" : parseFloat(value);
    } else {
      newValue = type === "checkbox" ? checked : value;
    }

    setEditedJob((prevState) => ({ ...prevState, [name]: newValue }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadingImage(true);

    try {
      const blob = new Blob([file], { type: file.type });
      const fileName = `public/job/${jobId}.jpg`;

      const result = await uploadData({
        path: fileName,
        data: blob,
        options: {
          onProgress: (progress) => console.log('Upload Progress:', progress),
        },
      }).result;

      const imageUrl = `https://mobilegiggz-storage-04cf5acf57017-dev.s3.us-west-2.amazonaws.com/${result.path}`;
      setEditedJob((prevState) => ({ ...prevState, imageUrl }));
      alert('Image uploaded successfully!');
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('Error uploading image. Please try again.');
    } finally {
      setUploadingImage(false);
    }
  };

  if (!job) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full max-w-4xl mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Edit Job</h2>

      {/* Position Dropdown */}
      <label className="block text-sm font-medium">Position Title</label>
      <select name="position" value={editedJob.position || ''} onChange={handleInputChange} className="w-full p-2 mb-4">
        {POSITION_ENUM.map((pos) => (
          <option key={pos.value} value={pos.value}>
            {pos.label}
          </option>
        ))}
      </select>

      {/* Short Description */}
      <label className="block text-sm font-medium text-gray-700">Short Description</label>
      <textarea
        name="shortDescription"
        value={editedJob.shortDescription || ""}
        onChange={handleInputChange}
        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
        placeholder="Enter short description"
      />

      {/* Detailed Description */}
      <label className="block text-sm font-medium text-gray-700">Detailed Description</label>
      <textarea
        name="description"
        value={editedJob.description || ""}
        onChange={handleInputChange}
        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
        placeholder="Enter detailed job description"
      />

      {/* Responsibilities */}
      <label className="block text-sm font-medium">Responsibilities</label>
      <textarea
        name="responsibilities"
        value={editedJob.responsibilities || ''}
        onChange={handleInputChange}
        className="w-full p-2 mb-4"
      />

      {/* Job Requirements */}
      <label className="block text-sm font-medium">Job Requirements</label>
      <textarea
        name="jobRequirements"
        value={editedJob.jobRequirements || ''}
        onChange={handleInputChange}
        className="w-full p-2 mb-4"
      />

      {/* Special Requirements Checkbox */}
      <div className="flex items-center space-x-2 mb-4">
        <input
          type="checkbox"
          name="specialRequirements"
          checked={editedJob.specialRequirements || false}
          onChange={handleInputChange}
          className="h-5 w-5"
        />
        <label className="text-sm font-medium">Special Requirements</label>
      </div>

      <label className="block text-sm font-medium text-gray-700">Start Time</label>
      <input
        type="time"
        name="startTime"
        value={editedJob.startTime || ""}
        onChange={handleInputChange}
        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
      />

      <label className="block text-sm font-medium text-gray-700">End Time</label>
      <input
        type="time"
        name="endTime"
        value={editedJob.endTime || ""}
        onChange={handleInputChange}
        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
      />


      {/* Date Input */}
      <label className="block text-sm font-medium text-gray-700">Start Date</label>
      <input
        type="date"
        name="startDate"
        value={editedJob.startDate || ""}
        onChange={handleInputChange}
        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
      />

      {/* Shift Type Dropdown */}
      <label className="block text-sm font-medium">Shift Type</label>
      <select name="shiftType" value={editedJob.shiftType || ''} onChange={handleInputChange} className="w-full p-2 mb-4">
        {SHIFT_TYPE_ENUM.map((shift) => (
          <option key={shift.value} value={shift.value}>
            {shift.label}
          </option>
        ))}
      </select>
      {/* Min Wage Input */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Minimum Wage</label>
        <input
          type="number"
          name="minWage"
          value={editedJob.minWage || ''}
          onChange={handleInputChange}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
          placeholder="Enter minimum wage"
        />
      </div>
      {/* Max Wage Input */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Maximum Wage</label>
        <input
          type="number"
          name="maxWage"
          value={editedJob.maxWage || ''}
          onChange={handleInputChange}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
          placeholder="Enter maximum wage"
        />
      </div>


      {/* Save as Template Checkbox */}
      <div className="flex items-center space-x-2 mb-4">
        <input
          type="checkbox"
          name="saveAsTemplate"
          checked={editedJob.saveAsTemplate || false}
          onChange={handleInputChange}
          className="h-5 w-5"
        />
        <label className="text-sm font-medium">Save as Template</label>
      </div>

      {editedJob.imageUrl && (
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700">Job Image</label>
          <img
            src={editedJob.imageUrl}
            alt="Job"
            className="object-cover w-full h-48 rounded-lg shadow-md mb-2"
          />
        </div>
      )}

      <div className="mb-6">
        <label htmlFor="imageUpload" className="block text-sm font-medium text-gray-700">
          Upload Job Image
        </label>
        <input
          type="file"
          id="imageUpload"
          onChange={handleImageUpload}
          className="mt-2"
          accept="image/*"
        />
        {uploadingImage && <p className="text-sm text-gray-500 mt-1">Uploading...</p>}
      </div>

      {/* Global Template Checkbox (for Super Users) */}
      {isSuperUser && editedJob.saveAsTemplate && (
        <div className="flex items-center space-x-2 mb-4">
          <input
            type="checkbox"
            name="isGlobal"
            checked={editedJob.isGlobal || false}
            onChange={handleInputChange}
            className="h-5 w-5"
          />
          <label className="text-sm font-medium">Save Globally for All Restaurants</label>
        </div>
      )}

      {/* Save and Cancel Buttons */}
      <div className="mt-6 flex space-x-4">
        <button onClick={handleSave} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center">
          <FiSave className="mr-2" /> Save
        </button>
        <button onClick={handleCancel} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 flex items-center">
          <FiX className="mr-2" /> Cancel
        </button>
      </div>
    </div>
  );
}

export default EditJob;
