import React, { useState, useEffect } from 'react';
import { DataStore } from 'aws-amplify/datastore';
import { Users, Restaurants } from '../models';
import { FiEdit, FiTrash, FiSave, FiX } from 'react-icons/fi';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');

  // Fetch users with roles RESTAURANTADMIN and SYSTEMADMIN
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userList = await DataStore.query(Users, (u) =>
          u.or((u) => [u.role.eq('RESTAURANTADMIN'), u.role.eq('SYSTEMADMIN')])
        );
        setUsers(userList);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const fetchRestaurants = async () => {
      try {
        const restaurantList = await DataStore.query(Restaurants);
        setRestaurants(restaurantList);
      } catch (error) {
        console.error('Error fetching restaurants:', error);
      }
    };

    fetchUsers();
    fetchRestaurants();
  }, []);

  // Handle user deletion
  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const userToDelete = await DataStore.query(Users, userId);
        if (userToDelete) {
          await DataStore.delete(userToDelete);
          setUsers(users.filter((user) => user.id !== userId));
        }
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  // Handle user editing
  const handleEdit = (user) => {
    setEditingUser(user);
    setSelectedRestaurant(user.restaurantsID || '');
  };

  // Handle saving the user and updating the restaurant's adminSubject
  const handleSave = async () => {
    try {
      if (selectedRestaurant) {
        // Update the user with the selected restaurant
        await DataStore.save(
          Users.copyOf(editingUser, (updated) => {
            updated.restaurantsID = selectedRestaurant;
          })
        );

        // Update the restaurant's adminSubject with the user's amplifyUserID
        const restaurantToUpdate = await DataStore.query(Restaurants, selectedRestaurant);
        if (restaurantToUpdate) {
          await DataStore.save(
            Restaurants.copyOf(restaurantToUpdate, (updated) => {
              updated.adminSubject = editingUser.amplifyUserID;
            })
          );
        }
      }

      setEditingUser(null);
      setSelectedRestaurant('');
      window.location.reload();
    } catch (error) {
      console.error('Error saving user or updating restaurant:', error);
    }
  };

  return (
    <div className="w-full max-w-6xl mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">User Management</h2>

      <table className="w-full table-auto">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Email</th>
            <th className="px-4 py-2">Role</th>
            <th className="px-4 py-2">Restaurant</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            // Find the restaurant where the user is an adminSubject
            const linkedRestaurant = restaurants.find((r) => r.adminSubject === user.amplifyUserID);

            return (
              <tr key={user.id} className="border-b">
                <td className="px-4 py-2">{user.name}</td>
                <td className="px-4 py-2">{user.email}</td>
                <td className="px-4 py-2">{user.role}</td>
                <td className="px-4 py-2">
                  {linkedRestaurant
                    ? linkedRestaurant.name
                    : user.restaurantsID
                    ? restaurants.find((r) => r.id === user.restaurantsID)?.name || 'Unlinked'
                    : 'Unlinked'}
                </td>
                <td className="px-4 py-2 space-x-2">
                  <button
                    className="text-blue-500 hover:text-blue-700"
                    onClick={() => handleEdit(user)}
                  >
                    <FiEdit />
                  </button>
                  <button
                    className="text-red-500 hover:text-red-700"
                    onClick={() => handleDelete(user.id)}
                  >
                    <FiTrash />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {editingUser && (
        <div className="mt-8 p-4 bg-gray-100 rounded-lg">
          <h3 className="text-xl font-bold mb-4">Edit User</h3>
          <div className="mb-4">
            <label className="block text-gray-700 mb-1">Assign Restaurant</label>
            <select
              value={selectedRestaurant}
              onChange={(e) => setSelectedRestaurant(e.target.value)}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
            >
              <option value="">Unlinked</option>
              {restaurants.map((restaurant) => (
                <option key={restaurant.id} value={restaurant.id}>
                  {restaurant.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex space-x-4">
            <button
              onClick={handleSave}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center"
            >
              <FiSave className="mr-2" /> Save
            </button>
            <button
              onClick={() => setEditingUser(null)}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 flex items-center"
            >
              <FiX className="mr-2" /> Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserManagement;
