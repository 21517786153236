// File: src/components/DashboardCard.js

import React from 'react';

function DashboardCard({ label, value, icon, onClick }) {
  return (
    <div
      className={`p-6 bg-white rounded-lg shadow-md flex items-center space-x-4 cursor-pointer ${onClick ? 'hover:bg-gray-100' : ''}`}
      onClick={onClick}
    >
      <div className="text-3xl text-gray-600">{icon}</div>
      <div>
        <p className="text-xl font-bold">{value}</p>
        <p className="text-gray-500">{label}</p>
      </div>
    </div>
  );
}

export default DashboardCard;
