// File: src/components/ApplicationDetails.js

import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { DataStore } from 'aws-amplify/datastore';
import { Application, Job, Users } from "../models";
import { FiArrowLeft, FiMail, FiPhone, FiEdit, FiSave } from "react-icons/fi";

function ApplicationDetails() {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [application, setApplication] = useState(null);
  const [applicant, setApplicant] = useState(null);
  const [job, setJob] = useState(null);
  const [status, setStatus] = useState('');
  const [comments, setComments] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchApplicationDetails = async () => {
      try {
        const applicationData = await DataStore.query(Application, applicationId);
        setApplication(applicationData);
        setStatus(applicationData?.status || '');
        setComments(applicationData?.comments || '');

        if (applicationData) {
          const userData = await DataStore.query(Users, applicationData.usersID);
          setApplicant(userData);

          const jobData = await DataStore.query(Job, applicationData.jobID);
          setJob(jobData);
        }
      } catch (error) {
        console.error("Error fetching application details:", error);
      }
    };

    fetchApplicationDetails();
  }, [applicationId]);

  const handleSaveChanges = async () => {
    console.log('Saving changes:', status, comments);
    try {
      await DataStore.save(
        Application.copyOf(application, (updated) => {
          updated.status = status;
          updated.comments = comments;
        })
      );
      alert('Application updated successfully!');
    } catch (error) {
      console.error("Error saving application details:", error);
      alert('Failed to save changes. Please try again.');
    }
  };

  if (!application) {
    return <div>Loading application details...</div>;
  }

  return (
    <div className="w-full max-w-4xl mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      {/* Back Button */}
      <div className="text-gray-500 text-sm mb-4 flex items-center">
        <button onClick={() => navigate(-1)} className="hover:underline flex items-center">
          <FiArrowLeft className="mr-1" /> Back to Applications
        </button>
      </div>

      {/* Application Details */}
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        Application Details
      </h2>

      <div className="space-y-4">
        <p><strong>Applicant Name:</strong> {applicant?.name || "Unknown"}</p>
        <p><strong>Email:</strong> {applicant?.email || "Not provided"}</p>
        <p><strong>Phone:</strong> {applicant?.phone || "Not provided"}</p>
        <p><strong>Job Title:</strong> {job?.name || "Unknown Job"}</p>
        <p><strong>Date Submitted:</strong> {new Date(application.dateSubmitted).toLocaleDateString()}</p>

        <div className="flex space-x-4 items-center">
          <strong>Status:</strong>
          {isEditing ? (
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="p-2 border rounded"
            >
              <option value="SUBMITTED">Submitted</option>
              <option value="PENDING">Pending</option>
              <option value="ACCEPTED">Accepted</option>
              <option value="REJECTED">Rejected</option>
              <option value="COMPLETED">Completed</option>
            </select>
          ) : (
            <span>{status}</span>
          )}
        </div>

        <div className="mt-4">
          <strong>Comments:</strong>
          {isEditing ? (
            <textarea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              className="w-full p-2 border rounded mt-2"
              placeholder="Add comments here"
            />
          ) : (
            <p>{comments || "No comments provided"}</p>
          )}
        </div>
      </div>

      {/* Action Buttons */}
      <div className="mt-6 flex space-x-4">
        <button
          onClick={() => setIsEditing((prev) => !prev)}
          className={`bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 flex items-center`}
        >
          <FiEdit className="mr-2" /> {isEditing ? 'Cancel' : 'Edit'}
        </button>
        {isEditing && (
          <button
            onClick={handleSaveChanges}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center"
          >
            <FiSave className="mr-2" /> Save Changes
          </button>
        )}
      </div>

      {/* Communication Options */}
      <div className="mt-8 flex space-x-4">
        {applicant?.email && (
          <a
            href={`mailto:${applicant.email}`}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center"
          >
            <FiMail className="mr-2" /> Email Applicant
          </a>
        )}
        {applicant?.phone && (
          <a
            href={`tel:${applicant.phone}`}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center"
          >
            <FiPhone className="mr-2" /> Call Applicant
          </a>
        )}
      </div>
    </div>
  );
}

export default ApplicationDetails;
