// File: src/components/RestaurantDetails.js 

import React, { useState, useEffect } from 'react';
import {
  FiEdit,
  FiSave,
  FiX,
  FiMapPin,
  FiPhone,
  FiPlus,
  FiUpload,
} from 'react-icons/fi';
import { DataStore } from 'aws-amplify/datastore';
import { uploadData } from 'aws-amplify/storage';
import { Restaurants, Job, Application } from '../models';
import { useParams, Link, useNavigate } from 'react-router-dom';

function RestaurantDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [restaurant, setRestaurant] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [applications, setApplications] = useState([]);
  const [recentApplications, setRecentApplications] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [activeTab, setActiveTab] = useState('jobs'); // Track active tab
  const [sortOption, setSortOption] = useState({ field: 'dateSubmitted', direction: 'desc' });

  // Load restaurant data and related jobs/applications
  useEffect(() => {
    const fetchData = async () => {
      try {
        const restaurantData = await DataStore.query(Restaurants, id);
        setRestaurant(restaurantData);

        const relatedJobs = await DataStore.query(Job, (j) => j.restaurantsID.eq(id));
        setJobs(relatedJobs);

        const relatedApplications = await DataStore.query(Application, (a) => a.restaurantsID.eq(id));
        const allApplications = [];
        const submittedApplications = [];

        for (const application of relatedApplications) {
          const job = await DataStore.query(Job, application.jobID);
          const appWithJob = { ...application, jobTitle: job?.name || 'Unknown Job' };
          allApplications.push(appWithJob);

          if (application.status === 'SUBMITTED') {
            submittedApplications.push(appWithJob);
          }
        }

        setApplications(allApplications.filter(app => app.status !== 'REJECTED'));
        setRecentApplications(submittedApplications.slice(0, 5)); // Limit to recent 5
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  const handleSave = async () => {
    try {
      await DataStore.save(
        Restaurants.copyOf(restaurant, (updated) => {
          Object.assign(updated, restaurant);
        })
      );
      setIsEditing(false);
      alert('Restaurant details updated successfully.');
    } catch (error) {
      console.error('Error saving restaurant info:', error);
      alert('Failed to update restaurant details.');
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRestaurant((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadingImage(true);

    try {
      console.log('Uploading image:', file);
      const blob = new Blob([file], { type: file.type });
      const fileName = `public/restaurant/${restaurant.id}.jpg`;
      console.log('Uploading image:', fileName);
      const result = await uploadData({
        path: fileName,
        data: blob,
        options: {
          onProgress: (progress) => console.log("Upload Progress:", progress),
        },
      }).result;
      console.log('Upload result:', result);
      const imageUrl = 'https://mobilegiggz-storage-04cf5acf57017-dev.s3.us-west-2.amazonaws.com/' + result.path; // This is the S3 key for the uploaded image
      console.log('Image uploaded:', imageUrl);

      console.log('restaurant:', restaurant);
      if (restaurant) {
        const updatedRestaurant = Restaurants.copyOf(restaurant, (updated) => {
          updated.imageURL = imageUrl;
        });
        console.log('updatedRestaurant:', updatedRestaurant);
        await DataStore.save(updatedRestaurant);
        setRestaurant(updatedRestaurant);
        alert("Image uploaded and restaurant updated successfully!");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Error uploading image. Please try again.");
    } finally {
      setUploadingImage(false);
    }
  };

  const handleCreateJob = () => {
    navigate(`/restaurants/${id}/create-job`);
  };

  // Sort applications by selected field
  const sortApplications = (field) => {
    const direction = sortOption.field === field && sortOption.direction === 'asc' ? 'desc' : 'asc';
    setSortOption({ field, direction });
    const sortedApplications = [...applications].sort((a, b) => {
      if (a[field] < b[field]) return direction === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setApplications(sortedApplications);
  };

  // Tab content render function
  const renderTabContent = () => {
    switch (activeTab) {
      case 'jobs':
        return (
          <div>
            <button onClick={handleCreateJob} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center mb-4">
              <FiPlus className="mr-2" /> Create New Job
            </button>
            <ul className="space-y-4">
              {jobs.map((job) => (
                <li key={job.id} className="bg-gray-100 p-4 rounded-lg flex justify-between items-center">
                  <div>
                    <p className="font-bold text-lg">{job.name}</p>
                    <p className="text-sm text-gray-600">Applications: {applications.filter(app => app.jobID === job.id).length}</p>
                  </div>
                  <button
                    onClick={() => navigate(`/edit-job/${job.id}`)}
                    className="px-2 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                  >
                    Edit Job
                  </button>
                </li>
              ))}
            </ul>
          </div>
        );
      case 'applications':
        return (
          <div>
            <div className="flex space-x-4 mb-4">
              <button onClick={() => sortApplications('dateSubmitted')} className="px-4 py-2 bg-gray-300 rounded">
                Sort by Date {sortOption.field === 'dateSubmitted' && (sortOption.direction === 'asc' ? '▲' : '▼')}
              </button>
              <button onClick={() => sortApplications('jobTitle')} className="px-4 py-2 bg-gray-300 rounded">
                Sort by Job {sortOption.field === 'jobTitle' && (sortOption.direction === 'asc' ? '▲' : '▼')}
              </button>
            </div>
            <ul className="space-y-4">
              {applications.map((app) => (
                <li
                  key={app.id}
                  onClick={() => navigate(`/applications/${app.id}`)}
                  className="bg-gray-100 p-4 rounded-lg cursor-pointer hover:bg-gray-200"
                >
                  <p className="font-bold text-lg">{app.applicantName}</p>
                  <p className="text-sm text-gray-600">Job: {app.jobTitle}</p>
                  <p className="text-sm text-gray-600">Status: {app.status}</p>
                  <p className="text-sm text-gray-600">Submitted: {new Date(app.dateSubmitted).toLocaleDateString()}</p>
                </li>
              ))}
            </ul>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full max-w-6xl mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      {/* Breadcrumb Navigation */}
      <div className="text-gray-500 text-sm mb-4">
        <Link to="/restaurants" className="hover:underline">Restaurants</Link> /{' '}
        <span className="font-semibold">{restaurant?.name || 'Restaurant Details'}</span>
      </div>

      {/* Restaurant Image */}
      {restaurant?.imageURL && (
        <div className="w-full h-64 mb-8 overflow-hidden rounded-lg">
          <img src={restaurant.imageURL} alt={restaurant?.name} className="object-cover w-full h-full" />
        </div>
      )}

      <div className="flex items-start space-x-6 mb-8">
        {/* Editable Restaurant Details */}
        {isEditing ? (
          <div className="flex-1">
            <input
              type="text"
              name="name"
              value={restaurant?.name || ''}
              onChange={handleChange}
              placeholder="Restaurant Name"
              className="w-full px-4 py-2 mb-4 rounded-lg border focus:outline-none"
            />
            <input
              type="text"
              name="streetAddress"
              value={restaurant?.streetAddress || ''}
              onChange={handleChange}
              placeholder="Street Address"
              className="w-full px-4 py-2 mb-4 rounded-lg border focus:outline-none"
            />
            <input
              type="text"
              name="city"
              value={restaurant?.city || ''}
              onChange={handleChange}
              placeholder="City"
              className="w-full px-4 py-2 mb-4 rounded-lg border focus:outline-none"
            />
            <input
              type="text"
              name="state"
              value={restaurant?.state || ''}
              onChange={handleChange}
              placeholder="State"
              className="w-full px-4 py-2 mb-4 rounded-lg border focus:outline-none"
            />
            <input
              type="text"
              name="zip"
              value={restaurant?.zip || ''}
              onChange={handleChange}
              placeholder="Zip Code"
              className="w-full px-4 py-2 mb-4 rounded-lg border focus:outline-none"
            />
            <input
              type="text"
              name="phone"
              value={restaurant?.phone || ''}
              onChange={handleChange}
              placeholder="Phone"
              className="w-full px-4 py-2 mb-4 rounded-lg border focus:outline-none"
            />
            <textarea
              name="description"
              value={restaurant?.description || ''}
              onChange={handleChange}
              placeholder="Description"
              className="w-full px-4 py-2 mb-4 rounded-lg border focus:outline-none"
              rows="4"
            />
            <div className="mt-4">
              <label htmlFor="imageUpload" className="flex items-center text-primary cursor-pointer">
                <FiUpload className="mr-2" /> Upload New Image
              </label>
              <input
                id="imageUpload"
                type="file"
                onChange={handleImageUpload}
                className="hidden"
              />
              {uploadingImage && <p className="text-sm text-gray-500 mt-2">Uploading...</p>}
            </div>
            <div className="flex justify-end space-x-4 mt-4">
              <button onClick={handleCancel} className="px-4 py-2 bg-gray-600 text-white rounded-lg">Cancel</button>
              <button onClick={handleSave} className="px-4 py-2 bg-primary text-white rounded-lg">Save</button>
            </div>
          </div>
        ) : (
          <div className="flex-1">
            <h2 className="text-3xl font-bold text-gray-800">{restaurant?.name || 'Loading...'}</h2>
            <p className="text-gray-600">
              <FiMapPin className="mr-1" /> {restaurant?.streetAddress}
            </p>
            <p className="text-gray-600">
              <FiPhone className="mr-1" /> {restaurant?.phone}
            </p>
            <p className="text-gray-600 mt-4">
              <strong>Description:</strong> {restaurant?.description || 'N/A'}
            </p>
          </div>
        )}
        <button
          onClick={() => setIsEditing((prev) => !prev)}
          className="px-4 py-2 bg-primary text-white rounded-lg flex items-center"
        >
          {isEditing ? <FiSave className="mr-2" /> : <FiEdit className="mr-2" />} {isEditing ? 'Save' : 'Edit'}
        </button>
      </div>

      {/* Summary/Metrics Dashboard */}
      <div className="grid grid-cols-3 gap-4 mb-8">
        <DashboardCard label="Total Jobs" value={jobs.length} onClick={() => setActiveTab('jobs')} />
        <DashboardCard label="All Applications" value={applications.length} onClick={() => setActiveTab('applications')} />
        <DashboardCard label="Recent Submissions" value={recentApplications.length} onClick={() => setActiveTab('applications')} />
      </div>

      {/* Tabs for Detailed Views */}
      <div className="mb-8">
        <div className="flex border-b border-gray-300 mb-4">
          <button onClick={() => setActiveTab('jobs')} className={`px-4 py-2 ${activeTab === 'jobs' ? 'border-b-2 border-primary text-primary' : 'text-gray-600'}`}>Jobs</button>
          <button onClick={() => setActiveTab('applications')} className={`px-4 py-2 ${activeTab === 'applications' ? 'border-b-2 border-primary text-primary' : 'text-gray-600'}`}>Applications</button>
          <button onClick={() => setActiveTab('notifications')} className={`px-4 py-2 ${activeTab === 'notifications' ? 'border-b-2 border-primary text-primary' : 'text-gray-600'}`}>Notifications</button>
        </div>
        {renderTabContent()}
      </div>
    </div>
  );
}

// Component for individual dashboard cards
function DashboardCard({ label, value, onClick }) {
  return (
    <div onClick={onClick} className="p-4 bg-gray-100 rounded-lg shadow-md hover:bg-gray-200 cursor-pointer">
      <h3 className="text-xl font-semibold">{label}</h3>
      <p className="text-3xl font-bold text-primary mt-2">{value}</p>
    </div>
  );
}

export default RestaurantDetails;
