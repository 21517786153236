import React, { useEffect, useState } from 'react';
import { DataStore } from 'aws-amplify/datastore';
import { Link, useNavigate } from 'react-router-dom';
import { Restaurants, Job, Application } from '../models';

function RestaurantList({ currentUser }) {
  const [restaurants, setRestaurants] = useState([]);
  const [jobCounts, setJobCounts] = useState({});
  const [applicationCounts, setApplicationCounts] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const results = currentUser.role === 'SYSTEMADMIN'
          ? await DataStore.query(Restaurants)
          : await DataStore.query(Restaurants, (r) => r.and(r => [r.adminSubject.eq(currentUser.amplifyUserID)]));

        setRestaurants(results);
      } catch (error) {
        console.error("Error fetching restaurants:", error);
      }
    };

    fetchRestaurants();
  }, [currentUser]);

  useEffect(() => {
    const fetchCounts = async () => {
      const jobCountMap = {};
      const applicationCountMap = {};

      const jobs = await DataStore.query(Job);
      jobs.forEach(job => {
        jobCountMap[job.restaurantsID] = (jobCountMap[job.restaurantsID] || 0) + 1;
      });

      const applications = await DataStore.query(Application);
      applications.forEach(application => {
        applicationCountMap[application.restaurantsID] = (applicationCountMap[application.restaurantsID] || 0) + 1;
      });

      setJobCounts(jobCountMap);
      setApplicationCounts(applicationCountMap);
    };

    fetchCounts();
  }, [restaurants]);

  useEffect(() => {
    const results = restaurants.filter((restaurant) =>
      restaurant.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRestaurants(results);
  }, [searchTerm, restaurants]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="w-full max-w-6xl mx-auto my-8">
      {/* Search and Create Section */}
      <div className="flex justify-between items-center mb-6">
        <input
          type="text"
          placeholder="Search Restaurants..."
          className="px-4 py-2 border rounded-lg focus:outline-none w-full max-w-md"
          value={searchTerm}
          onChange={handleSearch}
        />

        {/* Conditionally Render Create Restaurant Button */}
        {currentUser.role === 'SYSTEMADMIN' && (
          <button
            onClick={() => navigate('/create-restaurant')}
            className="ml-4 px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600"
          >
            Create Restaurant
          </button>
        )}
      </div>

      {/* Restaurants Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredRestaurants.map((restaurant) => (
          <Link
            key={restaurant.id}
            to={`/restaurant/${restaurant.id}`}
            className="bg-white rounded-lg shadow-md overflow-hidden transition-transform transform hover:scale-105"
          >
            <div className="relative h-40">
              <img
                src={restaurant.imageURL || '/placeholder-image.png'}
                alt={restaurant.name}
                className="object-cover w-full h-full"
              />
            </div>
            <div className="p-4">
              <h3 className="text-lg font-semibold text-gray-800">{restaurant.name}</h3>
              <div className="mt-2 text-gray-600 text-sm">
                <p>Open Jobs: {jobCounts[restaurant.id] || 0}</p>
                <p>Applications: {applicationCounts[restaurant.id] || 0}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default RestaurantList;
