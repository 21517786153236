// File: src/pages/JobsPage.js

import React, { useEffect, useState } from 'react';
import { DataStore } from 'aws-amplify/datastore';
import { Job, Restaurants } from '../models';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

function JobsPage() {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [sortOption, setSortOption] = useState({ field: 'startDate', direction: 'asc' });
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobsAndRestaurants = async () => {
      try {
        const jobData = await DataStore.query(Job);
        setJobs(jobData);
        setFilteredJobs(jobData);

        const restaurantData = await DataStore.query(Restaurants);
        setRestaurants(restaurantData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchJobsAndRestaurants();
  }, []);

  const handleSort = (field) => {
    const direction = sortOption.field === field && sortOption.direction === 'asc' ? 'desc' : 'asc';
    setSortOption({ field, direction });
    const sortedJobs = [...filteredJobs].sort((a, b) => {
      if (a[field] < b[field]) return direction === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setFilteredJobs(sortedJobs);
  };

  const handleStatusChange = async (job, newStatus) => {
    try {
      const updatedJob = await DataStore.query(Job, job.id);
      if (updatedJob) {
        await DataStore.save(
          Job.copyOf(updatedJob, (updated) => {
            updated.jobStatus = newStatus;
          })
        );
        setFilteredJobs((prevJobs) =>
          prevJobs.map((j) => (j.id === job.id ? { ...j, jobStatus: newStatus } : j))
        );
      }
    } catch (error) {
      console.error("Error updating job status:", error);
    }
  };

  const handleFilterByRestaurant = (restaurantId) => {
    setSelectedRestaurant(restaurantId);
    const filtered = restaurantId
      ? jobs.filter((job) => job.restaurantsID === restaurantId)
      : jobs;
    setFilteredJobs(filtered);
  };

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Jobs</h1>

      {/* Filter by Restaurant */}
      <div className="flex items-center mb-4 space-x-4">
        <div className="relative">
          <label className="block text-sm font-medium text-gray-700 mb-1">Filter by Restaurant</label>
          <select
            onChange={(e) => handleFilterByRestaurant(e.target.value || null)}
            value={selectedRestaurant || ''}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
          >
            <option value="">All Restaurants</option>
            {restaurants.map((restaurant) => (
              <option key={restaurant.id} value={restaurant.id}>
                {restaurant.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Sort options */}
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => handleSort('jobStatus')}
          className="flex items-center px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
        >
          Status {sortOption.field === 'jobStatus' && (sortOption.direction === 'asc' ? <FiArrowUp /> : <FiArrowDown />)}
        </button>
        <button
          onClick={() => handleSort('restaurantsID')}
          className="flex items-center px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
        >
          Restaurant {sortOption.field === 'restaurantsID' && (sortOption.direction === 'asc' ? <FiArrowUp /> : <FiArrowDown />)}
        </button>
        <button
          onClick={() => handleSort('startDate')}
          className="flex items-center px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
        >
          Date {sortOption.field === 'startDate' && (sortOption.direction === 'asc' ? <FiArrowUp /> : <FiArrowDown />)}
        </button>
      </div>

      {/* Jobs List */}
      <ul className="space-y-4">
        {filteredJobs.map((job) => (
          <li key={job.id} className="p-4 bg-gray-100 rounded-lg flex justify-between items-center">
            <div>
              <h3
                onClick={() => navigate(`/jobs/${job.id}`)}
                className="text-lg font-bold text-gray-800 cursor-pointer hover:underline"
              >
                {job.name}
              </h3>
              <p className="text-sm text-gray-600">Status: {job.jobStatus}</p>
              <p className="text-sm text-gray-600">
                Restaurant: {restaurants.find((r) => r.id === job.restaurantsID)?.name || 'N/A'}
              </p>
              <p className="text-sm text-gray-600">Start Date: {new Date(job.startDate).toLocaleDateString()}</p>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => handleStatusChange(job, 'OPEN')}
                className={`px-2 py-1 ${job.jobStatus === 'OPEN' ? 'bg-gray-300' : 'bg-green-500'} text-white rounded hover:bg-green-600`}
              >
                Open
              </button>
              <button
                onClick={() => handleStatusChange(job, 'FILLED')}
                className={`px-2 py-1 ${job.jobStatus === 'FILLED' ? 'bg-gray-300' : 'bg-blue-500'} text-white rounded hover:bg-blue-600`}
              >
                Filled
              </button>
              <button
                onClick={() => handleStatusChange(job, 'CANCELED')}
                className={`px-2 py-1 ${job.jobStatus === 'CANCELED' ? 'bg-gray-300' : 'bg-red-500'} text-white rounded hover:bg-red-600`}
              >
                Canceled
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default JobsPage;
