// File: src/components/CreateRestaurant.js

import React, { useState } from 'react';
import { DataStore } from 'aws-amplify/datastore';
import { Restaurants } from '../models';
import { useNavigate } from 'react-router-dom';

function CreateRestaurant() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    streetAddress: '',
    phone: '',
    imageURL: '',
    description: '' // New description field
  });
  const [isCreating, setIsCreating] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreate = async () => {
    setIsCreating(true);
    try {
      await DataStore.save(
        new Restaurants({
          name: formData.name,
          streetAddress: formData.streetAddress,
          phone: formData.phone,
          imageURL: formData.imageURL,
          description: formData.description, // Include description
          adminSubject: '' // Set this field based on your admin logic
        })
      );
      navigate('/restaurants');
    } catch (error) {
      console.error("Error creating restaurant:", error);
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <div className="w-full max-w-2xl mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Create New Restaurant</h2>
      <div className="space-y-4">
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Restaurant Name"
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
        />
        <input
          type="text"
          name="streetAddress"
          value={formData.streetAddress}
          onChange={handleChange}
          placeholder="Street Address"
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
        />
        <input
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Phone Number"
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
        />
        <input
          type="text"
          name="imageURL"
          value={formData.imageURL}
          onChange={handleChange}
          placeholder="Image URL"
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
        />
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Description"
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
          rows="4"
        />
      </div>

      <div className="mt-6">
        <button
          onClick={handleCreate}
          className="px-4 py-2 bg-green-500 text-white rounded-lg shadow hover:bg-green-600"
          disabled={isCreating}
        >
          {isCreating ? 'Creating...' : 'Create Restaurant'}
        </button>
        <button
          onClick={() => navigate('/restaurants')}
          className="ml-4 px-4 py-2 bg-red-500 text-white rounded-lg shadow hover:bg-red-600"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default CreateRestaurant;
