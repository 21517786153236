// File: src/components/JobDetails.js

import React, { useState, useEffect } from 'react';
import { FiEdit, FiTrash, FiUpload, FiSave, FiX } from 'react-icons/fi';
import { useParams, useNavigate } from 'react-router-dom';
import { DataStore } from 'aws-amplify/datastore';
import { Job, Restaurants } from '../models';

function JobDetails() {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [restaurant, setRestaurant] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const fetchJobAndRestaurant = async () => {
      try {
        const jobData = await DataStore.query(Job, jobId);
        setJob(jobData);
        
        if (jobData?.restaurantsID) {
          const restaurantData = await DataStore.query(Restaurants, jobData.restaurantsID);
          setRestaurant(restaurantData);
        }
      } catch (error) {
        console.error("Error fetching job or restaurant:", error);
      }
    };
    fetchJobAndRestaurant();
  }, [jobId]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'overview':
        return <JobOverview job={job} />;
      case 'manageJob':
        return <ManageJob navigate={navigate} jobId={jobId} />;
      case 'restaurantInfo':
        return <RestaurantInfo restaurant={restaurant} />;
      case 'images':
        return <ImageGallery />;
      default:
        return <JobOverview job={job} />;
    }
  };

  return (
    <div className="w-full max-w-6xl mx-auto my-8">
      {/* Breadcrumb Navigation */}
      <div className="text-gray-500 text-sm mb-4">
        <a href="/jobs" className="hover:underline">Jobs</a> / <span className="font-semibold">Job Details</span>
      </div>

      {/* Job Title and Status */}
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-3xl font-bold text-gray-800">{job?.name || 'Job Title Here'}</h2>
        <span className="text-sm font-semibold bg-green-200 text-green-700 px-2 py-1 rounded">{job?.jobStatus || 'Open'}</span>
      </div>

      {/* Tabs */}
      <div className="flex border-b border-gray-300 mb-4">
        <button onClick={() => setActiveTab('overview')} className={`px-4 py-2 ${activeTab === 'overview' ? 'border-b-2 border-primary text-primary' : 'text-gray-600'} focus:outline-none`}>
          Overview
        </button>
        <button onClick={() => setActiveTab('manageJob')} className={`px-4 py-2 ${activeTab === 'manageJob' ? 'border-b-2 border-primary text-primary' : 'text-gray-600'} focus:outline-none`}>
          Manage Job
        </button>
        <button onClick={() => setActiveTab('restaurantInfo')} className={`px-4 py-2 ${activeTab === 'restaurantInfo' ? 'border-b-2 border-primary text-primary' : 'text-gray-600'} focus:outline-none`}>
          Restaurant Info
        </button>
        <button onClick={() => setActiveTab('images')} className={`px-4 py-2 ${activeTab === 'images' ? 'border-b-2 border-primary text-primary' : 'text-gray-600'} focus:outline-none`}>
          Images
        </button>
      </div>

      {/* Tab Content */}
      {renderTabContent()}
    </div>
  );
}

function JobOverview({ job }) {
  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">Job Overview</h3>
      <p><strong>Wage:</strong> ${job?.minWage} - ${job?.maxWage} per hour</p>
      <p><strong>Duration:</strong> {job?.duration || 'Full-Time'}</p>
      <p><strong>Start Date:</strong> {job?.startDate || 'ASAP'}</p>
      <p><strong>Requirements:</strong> {job?.requirements || 'Previous experience in a similar role.'}</p>
    </div>
  );
}

function ManageJob({ navigate, jobId }) {
  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">Manage Job</h3>
      <button
        onClick={() => navigate(`/edit-job/${jobId}`)}
        className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 mb-2"
      >
        <FiEdit className="mr-2" /> Edit Job Details
      </button>
      <button className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
        Close Job
      </button>
    </div>
  );
}

function RestaurantInfo({ restaurant }) {
  if (!restaurant) {
    return <p>Loading restaurant information...</p>;
  }

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">Restaurant Information</h3>
      <p><strong>Name:</strong> {restaurant.name}</p>
      <p><strong>Address:</strong> {restaurant.address}</p>
      <p><strong>Phone:</strong> {restaurant.phone}</p>
      <p><strong>Description:</strong> {restaurant.description}</p>
    </div>
  );
}

function ImageGallery() {
  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">Image Gallery</h3>
      <p>Upload and manage restaurant images here.</p>
      <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
        <FiUpload className="inline mr-2" /> Upload Image
      </button>
    </div>
  );
}

export default JobDetails;
