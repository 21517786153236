// File: src/components/WelcomeSection.js

import React from 'react';

function WelcomeSection({ currentUser }) {
  return (
    <div className="bg-bg-light p-6 rounded-lg shadow-md w-full max-w-4xl mx-auto mb-8">
      <h2 className="text-3xl font-bold text-primary mb-2">
        Welcome back, {currentUser?.name}!
      </h2>
      <p className="text-text-gray text-lg mb-4">
        Role: <span className="font-semibold">{currentUser?.role}</span>
      </p>
      
      {/* Quick action buttons */}
      <div className="flex space-x-4">
        <button className="px-5 py-2 bg-primary text-white rounded-lg font-medium shadow hover:bg-primary-dark transition">
          Global Search
        </button>
        <button className="px-5 py-2 bg-primary text-white rounded-lg font-medium shadow hover:bg-primary-dark transition">
          View Recent Tasks
        </button>
      </div>
    </div>
  );
}

export default WelcomeSection;
