import React, { createContext, useState, useEffect, useContext } from 'react';
import {
  getCurrentUser,
  signIn as amplifySignIn,
  signOut as amplifySignOut,
} from 'aws-amplify/auth';
import { DataStore } from 'aws-amplify/datastore';
import { Users } from '../models';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAuthUser = async () => {
      try {
        const user = await getCurrentUser();
        setAuthUser(user);

        const userData = await DataStore.query(Users, (u) => u.amplifyUserID.eq(user.userId));
        if (userData.length > 0) {
          setCurrentUser(userData[0]);
          setRole(userData[0].role);
        }
      } catch (error) {
        console.error('Error fetching authenticated user:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAuthUser();
  }, []);

  const signIn = async (username, password) => {
    try {
      const signedInUser = await amplifySignIn({ username, password });
      setAuthUser(signedInUser);

      const userData = await DataStore.query(Users, (u) => u.amplifyUserID.eq(signedInUser.username));
      if (userData.length > 0) {
        setCurrentUser(userData[0]);
        setRole(userData[0].role);
      }
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  const signOut = async () => {
    await amplifySignOut();
    setAuthUser(null);
    setCurrentUser(null);
    setRole(null);
  };

  return (
    <AuthContext.Provider value={{ authUser, setAuthUser, currentUser, setCurrentUser, role, setRole, loading, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
