// src/App.js

import './index.css';
import { Routes, Route } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

import Sidebar from './components/Sidebar';
import WelcomeSection from './components/WelcomeSection';
import MetricsDashboard from './components/MetricsDashboard';
import QuickAccessCards from './components/QuickAccessCards';
import FeaturedInsights from './components/FeaturedInsights';
import RestaurantList from './components/RestaurantList';
import RestaurantDetails from './components/RestaurantDetails';
import EditJob from './components/EditJob';
import CreateJob from './components/CreateJob';
import ApplicationDetails from './components/ApplicationDetails';
import JobsPage from './components/JobsPage';
import JobDetails from './components/JobDetails';
import CreateRestaurant from './components/CreateRestaurant';
import Applications from './components/Applications';
import CreateJobTemplate from './components/CreateJobTemplate';
import UserManagement from './components/UserManagement';
import Settings from './components/Settings';
import AuthPage from './components/AuthPage';
import Notifications from './components/Notifications'; // Import the Notifications component
import { useAuthContext } from './contexts/AuthContext';

// Configure Amplify
Amplify.configure(awsExports);

function App() {
  const { currentUser, loading, signOut } = useAuthContext();

  if (loading) {
    return <div className="text-gray-600">Loading...</div>;
  }

  if (!currentUser) {
    return <AuthPage />;
  }

  return (
    <div className="flex min-h-screen">
      <Sidebar currentUser={currentUser} />
      <main className="flex-1 ml-64 p-8 bg-lightBg">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <WelcomeSection currentUser={currentUser} />
                <MetricsDashboard role={currentUser.role} />
                <QuickAccessCards />
                <FeaturedInsights />
              </>
            }
          />
          <Route path="/restaurants" element={<RestaurantList currentUser={currentUser} />} />
          <Route path="/restaurant/:id" element={<RestaurantDetails />} />
          <Route path="/edit-job/:jobId" element={<EditJob />} />
          <Route path="/restaurants/:id/create-job" element={<CreateJob />} />
          <Route path="/applications/:applicationId" element={<ApplicationDetails />} />
          <Route path="/jobs" element={<JobsPage />} />
          <Route path="/jobs/:jobId" element={<JobDetails />} />
          <Route path="/create-restaurant" element={<CreateRestaurant />} />
          <Route path="/applications" element={<Applications currentUser={currentUser} />} />
          <Route path="/notifications" element={<Notifications currentUser={currentUser} />} /> {/* New Notifications Route */}
          {currentUser.role === 'SYSTEMADMIN' && (
            <>
              <Route path="/user-management" element={<UserManagement />} />
              <Route path="/create-job-template" element={<CreateJobTemplate />} />
              <Route path="/settings" element={<Settings />} />
            </>
          )}
        </Routes>
        <button
          onClick={signOut}
          className="px-5 py-2 text-white font-semibold bg-primary rounded-lg shadow hover:bg-primary-dark transition mt-8"
        >
          Sign Out
        </button>
      </main>
    </div>
  );
}

export default App;
