// File: src/components/Settings.js

import React, { useState } from 'react';
import { FiPlus, FiLock, FiBell, FiDatabase, FiUsers } from 'react-icons/fi';

function Settings() {
  const [selectedCategory, setSelectedCategory] = useState("Job Templates");
  const categories = ["Job Templates", "User Permissions & Roles", "Notifications", "Integration & API", "Account & Security"];
  
  const renderContent = () => {
    switch (selectedCategory) {
      case "Job Templates":
        return <JobTemplateSettings />;
      case "User Permissions & Roles":
        return <UserPermissionsSettings />;
      case "Notifications":
        return <NotificationSettings />;
      case "Integration & API":
        return <APIIntegrationSettings />;
      case "Account & Security":
        return <AccountSecuritySettings />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col w-full p-8">
      <h1 className="text-3xl font-bold text-primary mb-8">Settings</h1>
      <div className="flex space-x-8">
        {/* Sidebar */}
        <div className="w-1/4 p-4 bg-lightBg shadow-lg rounded-lg">
          {categories.map((category, idx) => (
            <div
              key={idx}
              onClick={() => setSelectedCategory(category)}
              className={`flex items-center space-x-4 p-4 rounded-lg cursor-pointer hover:bg-accent transition-colors ${selectedCategory === category ? "bg-primary text-white" : "text-gray-700"}`}
            >
              <span className="text-lg">
                {category === "Job Templates" && <FiPlus />}
                {category === "User Permissions & Roles" && <FiUsers />}
                {category === "Notifications" && <FiBell />}
                {category === "Integration & API" && <FiDatabase />}
                {category === "Account & Security" && <FiLock />}
              </span>
              <span>{category}</span>
            </div>
          ))}
        </div>

        {/* Main Content */}
        <div className="flex-1 p-6 bg-white shadow-lg rounded-lg">
          {renderContent()}
        </div>
      </div>
    </div>
  );
}

const JobTemplateSettings = () => (
  <div>
    <h2 className="text-2xl font-semibold mb-4">Manage Job Templates</h2>
    <button className="bg-primary text-white px-4 py-2 rounded-lg shadow-md hover:bg-primary-dark mb-6">Create New Job Template</button>
    {/* Placeholder for job template list */}
    <p>List of templates...</p>
  </div>
);

const UserPermissionsSettings = () => (
  <div>
    <h2 className="text-2xl font-semibold mb-4">User Permissions & Roles</h2>
    {/* Placeholder for role assignment table */}
    <p>Role assignment details...</p>
  </div>
);

const NotificationSettings = () => (
  <div>
    <h2 className="text-2xl font-semibold mb-4">Notification Preferences</h2>
    {/* Placeholder for notification settings */}
    <p>Notification options...</p>
  </div>
);

const APIIntegrationSettings = () => (
  <div>
    <h2 className="text-2xl font-semibold mb-4">API Management & Integrations</h2>
    {/* Placeholder for API keys and integrations */}
    <p>API key management...</p>
  </div>
);

const AccountSecuritySettings = () => (
  <div>
    <h2 className="text-2xl font-semibold mb-4">Account & Security</h2>
    {/* Placeholder for account settings */}
    <p>Security options...</p>
  </div>
);

export default Settings;
