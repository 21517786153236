// File: src/components/Applications.js

import React, { useEffect, useState } from 'react';
import { DataStore } from 'aws-amplify/datastore';
import { Application, Users, Job, Restaurants } from '../models';
import { Link } from 'react-router-dom';
import { FiFilter, FiArrowUp, FiArrowDown } from 'react-icons/fi';

function Applications({ currentUser }) {
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [sortOption, setSortOption] = useState({ field: 'dateSubmitted', direction: 'asc' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch applications based on user role
        const applicationsData = currentUser.role === 'SYSTEMADMIN'
          ? await DataStore.query(Application)
          : await DataStore.query(Application, (a) => a.restaurantsID.eq(currentUser.restaurantID));

        // Fetch detailed information for each application
        const detailedApplications = await Promise.all(applicationsData.map(async (application) => {
          const user = await DataStore.query(Users, application.usersID);
          const job = await DataStore.query(Job, application.jobID);
          const restaurant = await DataStore.query(Restaurants, job.restaurantsID);
          return {
            ...application,
            applicantName: user?.name || 'Unknown',
            jobTitle: job?.name || 'Unknown Job',
            restaurantName: restaurant?.name || 'Unknown Restaurant',
          };
        }));

        setApplications(detailedApplications);
        setFilteredApplications(detailedApplications);

        // Fetch restaurants and users for filter options
        const restaurantData = await DataStore.query(Restaurants);
        const userData = await DataStore.query(Users);
        setRestaurants(restaurantData);
        setUsers(userData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentUser]);

  // Handle filter changes
  useEffect(() => {
    let result = [...applications];
    if (selectedRestaurant) result = result.filter(app => app.restaurantName === selectedRestaurant);
    if (selectedStatus) result = result.filter(app => app.status === selectedStatus);
    if (selectedUser) result = result.filter(app => app.applicantName === selectedUser);
    setFilteredApplications(result);
  }, [selectedRestaurant, selectedStatus, selectedUser, applications]);

  // Handle sorting
  const handleSort = (field) => {
    const direction = sortOption.field === field && sortOption.direction === 'asc' ? 'desc' : 'asc';
    setSortOption({ field, direction });
    const sorted = [...filteredApplications].sort((a, b) => {
      if (a[field] < b[field]) return direction === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setFilteredApplications(sorted);
  };

  return (
    <div className="w-full max-w-6xl mx-auto my-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold text-gray-800 mb-8">Applications</h1>

      {/* Filter Section */}
      <div className="flex flex-wrap gap-4 mb-6">
        <select
          onChange={(e) => setSelectedRestaurant(e.target.value)}
          value={selectedRestaurant}
          className="px-4 py-2 border rounded-lg w-1/4"
        >
          <option value="">All Restaurants</option>
          {restaurants.map((restaurant) => (
            <option key={restaurant.id} value={restaurant.name}>
              {restaurant.name}
            </option>
          ))}
        </select>
        <select
          onChange={(e) => setSelectedStatus(e.target.value)}
          value={selectedStatus}
          className="px-4 py-2 border rounded-lg w-1/4"
        >
          <option value="">All Statuses</option>
          <option value="SUBMITTED">Submitted</option>
          <option value="APPROVED">Approved</option>
          <option value="REJECTED">Rejected</option>
        </select>
        <select
          onChange={(e) => setSelectedUser(e.target.value)}
          value={selectedUser}
          className="px-4 py-2 border rounded-lg w-1/4"
        >
          <option value="">All Users</option>
          {users.map((user) => (
            <option key={user.id} value={user.name}>
              {user.name}
            </option>
          ))}
        </select>
      </div>

      {/* Sort Options */}
      <div className="flex justify-end mb-4">
        <button onClick={() => handleSort('dateSubmitted')} className="px-4 py-2 text-gray-600">
          Date {sortOption.field === 'dateSubmitted' && (sortOption.direction === 'asc' ? <FiArrowUp /> : <FiArrowDown />)}
        </button>
        <button onClick={() => handleSort('restaurantName')} className="px-4 py-2 text-gray-600">
          Restaurant {sortOption.field === 'restaurantName' && (sortOption.direction === 'asc' ? <FiArrowUp /> : <FiArrowDown />)}
        </button>
        <button onClick={() => handleSort('applicantName')} className="px-4 py-2 text-gray-600">
          User {sortOption.field === 'applicantName' && (sortOption.direction === 'asc' ? <FiArrowUp /> : <FiArrowDown />)}
        </button>
      </div>

      {/* Applications List */}
      <ul className="space-y-4">
        {filteredApplications.map((application) => (
          <li key={application.id} className="bg-gray-100 p-4 rounded-lg flex justify-between items-center">
            <div>
              <h3 className="text-lg font-bold text-gray-800">{application.applicantName}</h3>
              <p className="text-sm text-gray-600">Job: {application.jobTitle}</p>
              <p className="text-sm text-gray-600">Restaurant: {application.restaurantName}</p>
              <p className="text-sm text-gray-600">Status: {application.status}</p>
              <p className="text-sm text-gray-600">Date Submitted: {new Date(application.dateSubmitted).toLocaleDateString()}</p>
            </div>
            <Link to={`/applications/${application.id}`} className="text-primary hover:underline">
              View Details
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Applications;
