import React, { useState } from 'react';
import {
  signIn as amplifySignIn,
  signUp as amplifySignUp,
  confirmSignUp,
  getCurrentUser,
} from 'aws-amplify/auth';
import { DataStore } from 'aws-amplify/datastore';
import { Users } from '../models';
import { useAuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

function AuthPage() {
  const { setAuthUser, setCurrentUser, setRole } = useAuthContext();
  const navigate = useNavigate();

  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [awaitingVerification, setAwaitingVerification] = useState(false);
  const [loading, setLoading] = useState(false);

  // Handle Sign-Up
  const handleSignUp = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await amplifySignUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
            name,
          },
        },
      });

      setAwaitingVerification(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle Verification
  const handleVerify = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await confirmSignUp({ username: email, confirmationCode: verificationCode });

      // Sign in to get the current user and their sub ID
      const signedInUser = await amplifySignIn({ username: email, password });
      console.log('Signed in user after verify:', signedInUser);
      const newloggedinuser = await getCurrentUser();
      console.log('New logged in user:', newloggedinuser);
      

      // Save the new user to the database with role RESTAURANTADMIN and sub ID
      await DataStore.save(
        new Users({
          amplifyUserID: newloggedinuser.userId, // Save the sub ID
          name,
          email: newloggedinuser.signInDetails.loginId,
          role: 'RESTAURANTADMIN',
        })
      );

      alert('Verification successful! Please sign in.');
      setIsSignUp(false);
      setAwaitingVerification(false);
      setEmail('');
      setPassword('');
      setName('');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle Sign-In
  const handleSignIn = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
  
    try {
      const { isSignedIn, nextStep } = await amplifySignIn({ username: email, password });
      
      if (isSignedIn && nextStep.signInStep === 'DONE') {
        // Fetch the current authenticated user after sign-in
        const user = await getCurrentUser();
  
        console.log('Signed in user:', user);
  
        const userData = await DataStore.query(Users, (u) => u.amplifyUserID.eq(user.userId));
  
        if (userData.length > 0) {
          setAuthUser(user);
          setCurrentUser(userData[0]);
          setRole(userData[0].role);
  
          // Redirect to the dashboard after sign-in
          navigate('/');
        } else {
          setError('User data not found in the database.');
        }
      } else {
        setError('Sign-in process incomplete.');
      }
    } catch (err) {
      setError(`Error during sign-in: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-lightBg">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-primary">
          {isSignUp ? (awaitingVerification ? 'Verify Your Email' : 'Create an Account') : 'Sign In'}
        </h2>

        {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
        {loading && <p className="text-gray-600 mb-4 text-center">Loading...</p>}

        {awaitingVerification ? (
          <form onSubmit={handleVerify} className="space-y-4">
            <div>
              <label className="block text-gray-700 mb-1">Verification Code</label>
              <input
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <button type="submit" className="w-full bg-primary text-white py-2 rounded hover:bg-primary-dark transition">
              Verify
            </button>
          </form>
        ) : (
          <form onSubmit={isSignUp ? handleSignUp : handleSignIn} className="space-y-4">
            {isSignUp && (
              <div>
                <label className="block text-gray-700 mb-1">Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
                />
              </div>
            )}
            <div>
              <label className="block text-gray-700 mb-1">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-1">Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <button type="submit" className="w-full bg-primary text-white py-2 rounded hover:bg-primary-dark transition">
              {isSignUp ? 'Sign Up' : 'Sign In'}
            </button>
          </form>
        )}

        <p className="mt-4 text-center text-gray-700">
          {isSignUp ? 'Already have an account?' : "Don't have an account?"}{' '}
          <button
            type="button"
            onClick={() => {
              setIsSignUp(!isSignUp);
              setAwaitingVerification(false);
              setError(null);
            }}
            className="text-primary hover:underline"
          >
            {isSignUp ? 'Sign In' : 'Sign Up'}
          </button>
        </p>
      </div>
    </div>
  );
}

export default AuthPage;
