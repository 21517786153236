// File: src/components/QuickAccessCards.js
import React from 'react';
import { FiPlusCircle, FiEdit3, FiEye } from 'react-icons/fi';

function QuickAccessCards() {
  const actions = [
    { label: 'Add New Job', icon: <FiPlusCircle />, link: '#' },
    { label: 'Manage Restaurants', icon: <FiEdit3 />, link: '#' },
    { label: 'View Feedback', icon: <FiEye />, link: '#' },
  ];

  return (
    <div className="grid grid-cols-3 gap-4 w-full max-w-4xl mx-auto mb-8">
      {actions.map((action, index) => (
        <a
          key={index}
          href={action.link}
          className="bg-primary p-6 rounded-lg shadow-lg flex flex-col items-center justify-center text-white hover:bg-primary-dark transition"
        >
          <div className="text-4xl mb-2">{action.icon}</div>
          <h3 className="text-lg font-semibold">{action.label}</h3>
        </a>
      ))}
    </div>
  );
}

export default QuickAccessCards;
